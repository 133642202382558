body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter.ttf") format("truetype");
}

:root {
  --nav-inactive: #5d6682;
  --nav-active: #000;
  --nav-base: #1c274c;
  --success: #03ac2d;
  --danger: #ce181e;
  --warning: #f3aa18;
  --primary: #355ce4;
  --secondary: #f6973f;
  --light: #6d8bf7;
}

body {
  background-color: #ececec;
  position: relative;
  font-size: 16px;
}

* {
  user-select: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-size: 1rem; */
  font-family: "Inter", Arial, sans-serif;
  -webkit-tap-highlight-color: transparent;
}

.go3958317564 {
  font-size: 1rem !important;
  margin: 0 8px !important;
}

input,
textarea {
  user-select: text;
  width: 100%;
  max-width: 400px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #000;
  outline: none;
  padding: 11px 16px;
  font-size: 1rem;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  /* -webkit-box-shadow: 0 0 0 200px white inset !important; */
  transition: background-color 5000s ease-in-out 0s;
}

input::placeholder,
textarea::placeholder {
  font-size: 1rem;
}
textarea {
  resize: none;
}
/* * hide number input buttons  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

select,
select {
  user-select: text;
  width: 100%;
  max-width: 400px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #000;
  outline: none;
  font-size: 1rem;
  padding: 0 16px;
}

.App {
  width: 100%;
  height: 100dvh;
}

main {
  width: 100%;
  height: calc(100% - 60px);
  padding: 12px;
  position: absolute;
  top: 60px;
  left: 0;
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
  /* overflow: hidden; */
  transition: 0.4s;
}
main.extended {
  width: calc(100% - 110px);
  left: 110px;
}

input:disabled,
textarea:disabled {
  color: #000;
  user-select: none;
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For Internet Explorer/Edge */
}

h1 {
  font-size: 1.2rem;
  font-weight: 800;
  text-align: center;
}
h2 {
  font-size: 1.05rem;
  font-weight: 700;
  text-align: center;
}
h3 {
  font-size: 1.15rem;
  font-weight: 600;
}
h4 {
  font-size: 1rem;
  font-weight: 500;
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: fit-content;
  /* height: 36px; */
  padding: 8px 20px;
  border: none;
  color: #fff;
  font-size: 1.15rem;
  cursor: pointer;
  font-weight: 500;
  border-radius: 8px;
}
button p {
  font-size: 1.15rem;
  font-weight: 500;
}
button svg {
  width: 20px;
  height: 20px;
}

button.pill {
  border-radius: 30px;
}
button.cancel {
  background-color: #e5e6eb;
  color: #757575;
  border: 1px solid #000;
}
button.outline {
  border: 1px solid #000;
  background-color: #0000;
  color: #000;
}
button.gray {
  background-color: #e5e6eb;
  color: #3d3d3d;
}

.success {
  background-color: var(--success) !important;
}
.danger {
  background-color: var(--danger) !important;
}
.warning {
  background-color: var(--warning) !important;
}
.primary {
  background-color: var(--primary);
}
.secondary {
  background-color: var(--secondary);
}
.success-outline {
  background-color: #0000;
  border: 1px solid var(--success);

  color: var(--success);
}

::-webkit-scrollbar {
  display: none;
  width: 0;
  /* width: 16px; */
  /** don't forget to add padding to listDisplay */
}
::-webkit-scrollbar-track {
  background: #d7e0ff;
}
::-webkit-scrollbar-thumb {
  background: #8ca5ff;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background: #3e68ff;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

#turtle-loading-icon .st0 {
  fill: #70cc40;
}
#turtle-loading-icon .st1 {
  fill: #093f68;
}
#turtle-loading-icon .st2 {
  fill: #f56132;
}
#turtle-loading-icon .st3 {
  fill: #ffffff;
}
#turtle-loading-icon .st4 {
  fill: #68e1fd;
}
#turtle-loading-icon .st5 {
  fill: #ffbc0e;
}

/* svg */
#eye-icon {
  color: #808080;
}
