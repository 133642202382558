.productDetail {
  width: 100dvw;
  height: 100dvh;
  background-color: #e5e5e5;
  position: absolute;
  top: 0;
  left: 0;
  /* padding: 12px; */
  z-index: 3;
  overflow-x: hidden;
}
.productDetail .header {
  width: 100%;
  height: 50px;
  /* border: 1px solid #000; */
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  position: sticky;
  top: 0;
  background-color: #fff;
}
.productDetail .header .product-id {
  width: 100%;
  text-align: left;
}
.productDetail .header .icons {
  width: 100px;
}
.productDetail .header .right {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.productDetail .header svg {
  width: 32px;
  height: 32px;
  /* background-color: #eee; */
  /* box-shadow: 2px 2px 3px #888; */
}
.productDetail .header p {
  font-size: 1.1rem;
}
.productDetail .aDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.productDetail .main_image {
  max-width: 600px;
  height: 320px;
  /* aspect-ratio: 1; */
  width: 100%;
  object-fit: contain;
  /* background-color: #fff; */
}
.productDetail .sub_images {
  max-width: 100%;
  width: fit-content;
  height: 86px;
  display: flex;
  gap: 12px;
  overflow: scroll;
  align-items: center;
  padding: 0 12px;
}
.productDetail .sub_images img {
  width: 70px;
  height: 70px;
  border-radius: 4px;
  border: 1px solid #000;
  object-fit: contain;
  background-color: #fff;
}
.productDetail .sub_images img.selected {
  border: 2px solid var(--secondary);
  /* width: 72px;
  height: 72px; */
}

.productDetail .detail-container {
  width: 100%;
  padding: 12px;
}
.productDetail .detail-container h1 {
  text-align: left;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 8px;
}
.productDetail .detail-container .detail {
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  /* font-size: 1.1rem; */
  white-space: pre-wrap;
}

.productDetail .price-container {
  width: calc(100% - 20px);
  max-width: 600px;
  margin: 0 12px;
  padding: 16px 12px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  gap: 12px;
  margin-bottom: 16px;
}
.productDetail .ks {
  font-size: 0.85rem;
}
.productDetail .price {
  font-size: 1.1rem;
  color: #ff7325;
  font-weight: 500;
  min-width: fit-content;
}

.productDetail .loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 50px);
  padding-bottom: 15%;
}
.productDetail .loading-indicator svg use {
  width: 240px;
}

.productDetail .menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  right: -200px;
  background-color: #fff;
  transition: 0.3s;
}

.productDetail .menu li {
  list-style: none;
  width: 160px;
  padding: 12px 16px;
  border-bottom: 1px solid #000;
  display: flex;
  align-items: center;
  gap: 12px;
}
.productDetail .menu li svg {
  width: 24px;
  height: 24px;
}
.productDetail .menu.slideIn {
  right: 0;
}
.productDetail #eye-icon {
  color: var(--success) !important; /* overriding the default color */
}
