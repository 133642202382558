.popup,
.popupPage {
  display: flex !important;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000a;
  justify-content: center;
  align-items: center;
  padding: 12px;
  overflow-x: hidden;
}

.popup .inner {
  max-width: 600px;
  min-width: 300px;
  width: fit-content;
  height: fit-content;
  padding: 20px 20px 100px 20px;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  gap: 16px;
}
.deco {
  width: 50%;
  max-width: 400px;
  height: 6px;
  border-radius: 0 0 4px 4px;
  background-color: var(--primary);
  position: absolute;
  top: 0;
}

.popup .close {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 12px;
  right: 8px;
}

.popup .trash-can {
  width: 50px;
  height: 50px;
  background-color: var(--danger);
  padding: 12px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup .trash-can svg {
  height: 100%;
  aspect-ratio: 1;
}

.popup .right p {
  margin-top: 12px;
  font-size: 14px;
  color: #999;
  margin-left: 12px;
}

.popup .actions {
  width: 100%;
  height: 60px;
  background-color: #efefef;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 20px;
  gap: 20px;
}

.popup h1 {
  position: relative;
  top: -12px;
}

.popup .inner.row {
  flex-direction: row;
  padding-top: 60px;
}

.popupPage {
  position: absolute;
  background-color: #f4f4f4;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  padding: 8px;
  padding-bottom: 0;
}
.popupPage .page-title {
  padding-left: 16px;
}
.popupPage .body {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  padding: 12px;
}
.popupPage .actions {
  max-width: 600px;
  width: 100%;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  padding: 12px;
}
.popup2 .inner {
  width: 540px;
  padding: 40px 40px 20px 40px;
}
.popup2 h1 {
  top: 0;
}
.popup2 .actions {
  justify-content: center;
  padding-right: 0;
  background-color: #0000;
  height: fit-content;
  position: relative;
  margin-top: 12px;
}
.popup2 button {
  width: 160px;
}

/* .popup2 input[disabled],
.popup2 textarea[disabled] {
  color: #000;
} */

.popup2 .reserved-list {
  width: 100%;
  border-top: 1px solid #000;
  padding: 12px 20px 0 20px;
  display: flex;
  flex-direction: column;
}
.popup2 .reserved-list > div {
  width: 100%;
  display: flex;
  height: 48px;
  /* gap: 12px; */
  justify-content: space-between;
  align-items: center;
}
.popup2 .reserved-list .name {
  width: 200px;
  text-align: left;
}
