.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 10px 10px 4px 10px;
}

.home .add-new-lottery {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #a2ffff;
  /* border: 1px solid #000; */
  box-shadow: 1px 3px 3px #0008;
  position: fixed;
  bottom: 32px;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.home .add-new-lottery svg {
  width: 32px;
  height: 32px;
}
.home .search-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
