.login-page {
  width: 100dvw;
  height: 100dvh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.login-form {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 5px 5px 8px #0003;
  position: relative;
}

.login-form button {
  width: calc(100% - 100px);
  background-color: var(--primary);
}
