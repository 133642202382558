.brandCard {
  width: calc(50% - 3px);
  max-width: 200px;
  margin-top: 24px;
}
.brandCard img {
  border: 2px solid #000;
  border-radius: 20px;
}

@media only screen and (max-width: 360px) {
  .brandCard {
    width: 100%;
    max-width: none;
  }
}
