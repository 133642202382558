.box-display {
  display: flex;
  width: 100%;
  overflow: visible;
  height: 100%;
  padding-top: 4px;
}

.box-display .action svg {
  width: 28px;
  height: 28px;
  overflow: visible;
}
.box-display .loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 15%;
}
.box-display .loading-indicator svg use {
  width: 240px;
}
.box-display .box-content {
  gap: 6px;
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-y: scroll;
  padding: 6px 0 10px 0;
}
.box-display .boxes {
  width: calc(50% - 3px);
  /* min-width: 120px; */
  max-width: 200px;
  height: fit-content;
  background-color: #fff;
  /* box-shadow: 2px 2px 4px #0003; */
  border-radius: 6px;
  padding: 0 10px 4px 10px;
  position: relative;
}
.box-display .boxes p {
  line-height: 1.7rem;
}

.box-display .boxes .title {
  width: 100%;
  font-size: 1.1rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  overflow: hidden;
  text-overflow: ellipsis;
}
.box-display .mute {
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 8px;
  right: 12px;
}
/* 

.box-display .action {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 10px;
  position: absolute;
  z-index: 1;
  bottom: 40px;
  right: 4px;
  border: 1px solid #000;
  box-shadow: -2px 2px 4px #0007;
} */

.box-display .box-content img {
  /* height: 140px; */
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  margin-bottom: 8px;
}
/* .box-display .img-container {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
} */
/* .loading-image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}
.box-display .loading-image {
  border: 1px dashed var(--light);
  width: 120px;
  height: 120px;
} */

.box-display .ks {
  font-size: 0.85rem;
}
.box-content .price {
  font-size: 1.1rem;
  color: #ff7325;
  font-weight: 500;
  margin-top: 6px;
}

.box-display .click_me {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 360px) {
  .box-display .boxes {
    width: 100%;
    max-width: none;
  }
}
