.inputBox,
.inputBox2,
.searchBox,
.smallInputGroup,
.dropDownBox2,
.textArea,
.imageUploadBox,
.RangeInputBox {
  width: 100%;
  position: relative;
  overflow: visible;
  display: flex;
  /* min-width: 400px; */
  max-width: 600px;
  justify-content: space-between;
  align-items: center;
}
.inputBox,
.searchBox {
  min-width: 0;
}
.searchBox {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #000;
  padding-right: 12px;
}
.searchBox input {
  width: 100%;
  border: none;
  min-width: 0;
  max-width: none;
}
.searchBox svg {
  cursor: pointer;
}

.inputBox span,
.textArea p {
  width: fit-content;
  padding: 4px 10px;
  background-color: #fff;
  position: absolute;
  left: 8px;
  top: 7px;
  font-size: 0.95rem;
  transition: 0.2s;
  border-radius: 6px;
  pointer-events: none;
  opacity: 0.6;
}
.inputBox input:focus + span,
.inputBox input:valid + span,
.textArea textarea:focus + p,
.textArea .valid {
  top: -17px;
  font-size: 0.85rem;
  opacity: 1;
}
.inputBox svg,
.inputBox2 svg {
  width: 28px;
  aspect-ratio: 1;
  position: absolute;
  top: 6.5px;
  right: 14px;
}
.inputBox svg.open,
.inputBox2 svg.open {
  top: 8px;
}

.smallInputGroup,
.textArea,
.imageUploadBox {
  align-items: flex-start;
}
.inputBox2 p,
/* .textArea p, */
.smallInputGroup p,
.imageUploadBox p,
.RangeInputBox p {
  width: calc(30% - 12px);
  text-align: right;
  font-weight: 500;
}

.inputBox2 input,
.dropDownBox2 select,
.smallInputGroup .container,
.RangeInputBox .container {
  width: 70%;
}

.textArea textarea {
  min-height: 120px;
  max-height: 400px;
}

/* .textArea p {
  width: fit-content;
  padding: 4px 6px;
} */

.smallInputGroup p,
.imageUploadBox p {
  height: 40px;
  line-height: 40px;
}
.smallInputGroup .container input {
  width: 90px;
  text-align: center;
  padding: 8px;
}
.smallInputGroup .container {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  max-width: 400px;
}

.smallInputGroup .highlight {
  border-color: var(--danger);
  border-width: 2px;
}

.shake {
  animation: shake 0.2s linear;
}

@keyframes shake {
  0% {
    transform: translate(0px, 0px);
  }
  20% {
    transform: translate(10px, 0px);
  }
  80% {
    transform: translate(-10px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

.RangeInputBox .container input {
  max-width: 120px;
  text-align: center;
}

.RangeInputBox .container {
  max-width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}
