.editProduct {
  display: flex !important;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ececec;
  justify-content: center;
  align-items: center;
  /* padding: 20px; */
  overflow-x: hidden;
  z-index: 3;
}
.editProduct header {
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 2;
}
.editProduct header svg {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 16px;
}

.editProduct .body {
  width: 100vw;
  height: calc(100vh - 50px);
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  overflow: scroll;
  position: absolute;
  top: 50px;
  left: 0;
}

.editProduct button {
  margin-top: 20px;
}
